/***
 *
 *   CONTACT FORM
 *   Will send an email notification to you
 *
 **********/

import React from 'react';
import { Row, SalesContact } from 'components/lib';

export function Sales(props){

  return(
    <Row title='Enteprise Support'>
      <SalesContact />
    </Row>
  )
}
