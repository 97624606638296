/***
*
*   PRIVACY POLICY
*   Boilerplate privacy statement – replace the values
*   inside [] with your own text
*
**********/

import React from 'react';
import { Article } from 'components/lib';

export function ExampleList(props){

  return(
    <Article>

      <h1>User List Example - PHI</h1>

        <table width="85%" cellspacing="10">
            <tbody>
            <tr>
                <td width="15%" style={{fontWeight: "bold"}}>First Name</td>
                <td width="15%" style={{fontWeight: "bold"}}>Last Name</td>
                <td width="25%" style={{fontWeight: "bold"}}>SSN</td>
                <td width="25%" style={{fontWeight: "bold"}}>Data of Birth</td>
                <td width="20%">&nbsp;</td>
            </tr>
            <tr>
                <td class="patientFirstName">Robert</td>
                <td class="patientLastName">Aragon</td>
                <td class="ssn">489-36-8350</td>
                <td class="dob">6/7/<span class="dobYear">1981</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Ashley</td>
                <td class="patientLastName">Borden</td>
                <td class="ssn">514-14-8905</td>
                <td class="dob">7/8/<span class="dobYear">1981</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Thomas</td>
                <td class="patientLastName">Conley</td>
                <td class="ssn">690-05-5315</td>
                <td class="dob">8/9/<span class="dobYear">1981</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Susan</td>
                <td class="patientLastName">Davis</td>
                <td class="ssn">421-37-1396</td>
                <td class="dob">9/10/<span class="dobYear">1981</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Christopher</td>
                <td class="patientLastName">Diaz</td>
                <td class="ssn">458-02-6124</td>
                <td class="dob">1/10/<span class="dobYear">1975</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Rick</td>
                <td class="patientLastName">Edwards</td>
                <td class="ssn">612-20-6832</td>
                <td class="dob">2/11/<span class="dobYear">1975</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Victor</td>
                <td class="patientLastName">Faulkner</td>
                <td class="ssn">300-62-3266</td>
                <td class="dob">3/12/<span class="dobYear">1975</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Lisa</td>
                <td class="patientLastName">Garrison</td>
                <td class="ssn">660-03-8360</td>
                <td class="dob">4/13/<span class="dobYear">1975</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Marjorie</td>
                <td class="patientLastName">Green</td>
                <td class="ssn">213-46-8915</td>
                <td class="dob">5/14/<span class="dobYear">1975</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Mark</td>
                <td class="patientLastName">Hall</td>
                <td class="ssn">449-48-3135</td>
                <td class="dob">6/14/<span class="dobYear">1975</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">James</td>
                <td class="patientLastName">Heard</td>
                <td class="ssn">559-81-1301</td>
                <td class="dob">7/16/<span class="dobYear">1975</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Albert</td>
                <td class="patientLastName">Iorio</td>
                <td class="ssn">322-84-2281</td>
                <td class="dob">8/17/<span class="dobYear">1975</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Charles</td>
                <td class="patientLastName">Jackson</td>
                <td class="ssn">646-44-9061</td>
                <td class="dob">9/18/<span class="dobYear">1975</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Teresa</td>
                <td class="patientLastName">Kaminski</td>
                <td class="ssn">465-73-5022</td>
                <td class="dob">10/19/<span class="dobYear">1975</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Tim</td>
                <td class="patientLastName">Lowe</td>
                <td class="ssn">044-34-6954</td>
                <td class="dob">1/20/<span class="dobYear">1991</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Monte</td>
                <td class="patientLastName">Mceachern</td>
                <td class="ssn">477-36-0282</td>
                <td class="dob">2/21/<span class="dobYear">1991</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Adriane</td>
                <td class="patientLastName">Morrison</td>
                <td class="ssn">421-90-3440</td>
                <td class="dob">3/22/<span class="dobYear">1991</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Jerome</td>
                <td class="patientLastName">Munsch</td>
                <td class="ssn">524-02-7657</td>
                <td class="dob">4/23/<span class="dobYear">1991</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Agnes</td>
                <td class="patientLastName">Nelson</td>
                <td class="ssn">205-52-0027</td>
                <td class="dob">5/24/<span class="dobYear">1991</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Lynette</td>
                <td class="patientLastName">Oyola</td>
                <td class="ssn">587-03-2682</td>
                <td class="dob">6/25/<span class="dobYear">1991</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Stacey</td>
                <td class="patientLastName">Peacock</td>
                <td class="ssn">687-05-8365</td>
                <td class="dob">2/18/<span class="dobYear">1968</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Julie</td>
                <td class="patientLastName">Renfro</td>
                <td class="ssn">751-01-2327</td>
                <td class="dob">3/19/<span class="dobYear">1968</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Danny</td>
                <td class="patientLastName">Reyes</td>
                <td class="ssn">624-84-9181</td>
                <td class="dob">4/20/<span class="dobYear">1968</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Jacki</td>
                <td class="patientLastName">Russell</td>
                <td class="ssn">514-30-2668</td>
                <td class="dob">5/21/<span class="dobYear">1968</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Thomas</td>
                <td class="patientLastName">Santos</td>
                <td class="ssn">451-80-3526</td>
                <td class="dob">6/22/<span class="dobYear">1968</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Mireille</td>
                <td class="patientLastName">Townsend</td>
                <td class="ssn">404-12-2154</td>
                <td class="dob">7/23/<span class="dobYear">1968</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Lillian</td>
                <td class="patientLastName">Venson</td>
                <td class="ssn">505-88-5714</td>
                <td class="dob">2/1/<span class="dobYear">1968</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Gail</td>
                <td class="patientLastName">Watson</td>
                <td class="ssn">461-97-5660</td>
                <td class="dob">2/2/<span class="dobYear">1968</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Johnson</td>
                <td class="patientLastName">White</td>
                <td class="ssn">172-32-1176</td>
                <td class="dob">2/3/<span class="dobYear">1968</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            <tr>
                <td class="patientFirstName">Rebecca</td>
                <td class="patientLastName">Zwick</td>
                <td class="ssn">151-32-2558</td>
                <td class="dob">2/4/<span class="dobYear">1968</span></td>
                <td><a href="/example-details">Details</a></td>
            </tr>
            </tbody>
        </table>

    </Article>
  )
}
