import { Home } from 'views/website/home';
import { Contact } from 'views/website/contact';
import { Sales } from 'views/website/sales';

import { Pricing } from 'views/website/pricing';
import { Terms } from 'views/website/terms';
import { Privacy } from 'views/website/privacy';

import { ExampleList } from 'views/website/example-list';
import { ExampleDetails } from 'views/website/example-details';

const Routes = [
  {
    path: '/',
    view: Home,
    layout: 'home',
    title: "Secure your protected data"
  },
  {
    path: '/contact',
    view: Contact,
    layout: 'home',
    title: 'Contact'
  },
  {
    path: '/pricing',
    view: Pricing,
    layout: 'home',
    title: 'Pricing'
  },
  {
    path: '/privacy',
    view: Privacy,
    layout: 'home',
    title: 'Privacy Policy'
  },
  {
    path: '/terms',
    view: Terms,
    layout: 'home',
    title: 'Terms & Conditions'
  },
  {
    path: '/example-list',
    view: ExampleList,
    layout: 'home',
    title: 'PHI Example List'
  },
  {
    path: '/example-details',
    view: ExampleDetails,
    layout: 'home',
    title: 'PHI Example Details'
  }, {
    path: '/sales',
    view: Sales,
    layout: 'home',
    title: 'Enterprise Contact'
  },
]

export default Routes;