import Zoom from "react-reveal/Zoom";

export function RedactedItem(props){

    let hideClass = `hide-${Math.random()}`;

    const RedactAnim = (redact) => {
    let text = redact.text || "Protected";
    let style = { zIndex: 100, position:"absolute", top:"-0.2em", left: "0", padding: "0.2em", backgroundColor: "#d95565", color: "#fff", borderRadius: "4px", fontWeight: "300", };
    let delay = Math.floor(Math.random() * 2500) + 250;
    let duration = Math.floor(Math.random() * 1500) + 500;
    return (
        <Zoom delay={delay} wait={delay + duration + 100} duration={duration} onReveal={ () => { document.getElementById(hideClass).style.visibility = "hidden"; } }>
          <div style={style} >
            {text}
          </div>
        </Zoom>
    )};

    let redacted = props.disabled ? '' : <RedactAnim text={props.text} />;
    return (
        <span style={{ position:"relative", whiteSpace:"nowrap", }}>
          {redacted}
          <div id={hideClass} style={{ zIndex:1, position:"absolute", top:"0", left:"0", }} >
            {props.children}
          </div>
        </span>
    );

}
