/***
*
*   PRIVACY POLICY
*   Boilerplate privacy statement – replace the values
*   inside [] with your own text
*
**********/

import React from 'react';
import {Article, Badge, Card, Content} from 'components/lib';

export function ExampleDetails(props){

  return(
    <Article>

      <h1>Patient Example - PHI</h1>

        <Card title='Patient Details' color='purple'>

                <table width="100%" cellspacing="10">
                    <tr>
                        <td width="30em">First Name:</td>
                        <td width="30%" class="detailsFirstName">Tom</td>
                        <td width="30em">Last Name:</td>
                        <td width="30%" class="detailsLastName">Smith</td>
                    </tr>
                    <tr>
                        <td width="30em">SSN:</td>
                        <td width="30%" className="detailsSSN">123-45-6789</td>
                        <td width="30em">Date of Birth:</td>
                        <td width="30%" className="detailsDOB">1 / 2 / <span className="detailsDOBYear">1934</span></td>
                    </tr>
                    <tr>
                        <td width="30em">Home Phone:</td>
                        <td width="30%" class="detailsHomePhone">555-<span class="detailsHomePhoneLastSeven">123-4567</span></td>
                        <td width="30em">Work Phone:</td>
                        <td width="30%" class="detailsWorkPhone">555-<span className="detailsWorkPhoneLastSeven">765-4321</span></td>
                    </tr>
                    <tr>
                        <td width="30em">Can Contact Home:</td>
                        <td width="30%" className="detailsCanContactHome">Yes</td>
                        <td width="30em">Can Contact Work:</td>
                        <td width="30%" className="detailsCanContactWork">No</td>
                    </tr>
                    <tr>
                        <td width="30em">Date of Last Contact:</td>
                        <td width="30%" className="detailsDateLastContact">1 / 1 / 2000</td>
                        <td width="30em">Date of Next Contact:</td>
                        <td width="30%" className="detailsDateNextContact">2 / 2 / 2001</td>
                    </tr>
                </table>

        </Card>

        <Card title='Patient Insurance' color='purple'>
            <table width="100%" cellSpacing="10">
                <tr>
                    <td width="30em">Provider:</td>
                    <td width="30%" className="detailsProvider">ABC Health Insurance</td>
                    <td width="30em">Policy Number:</td>
                    <td width="30%" className="detailsPolicyNumber">INS-1234567890</td>
                </tr>
                <tr>
                    <td width="30em">Group Number:</td>
                    <td width="30%" className="detailsGroupNumber">1234321</td>
                    <td width="30em">Group Name:</td>
                    <td width="30%" className="detailsGroupName">XYZ Company</td>
                </tr>
            </table>
        </Card>

        <Card title='Patient Condition' color='purple'>
            <table width="100%" cellSpacing="10">
                <tr>
                    <td width="30em">Condition:</td>
                    <td width="30%" className="detailsCondition">Halitosis</td>
                    <td width="1em">&nbsp;</td>
                    <td width="15%" className="">&nbsp;</td>
                </tr>
                <tr>
                    <td width="30em" style={{verticalAlign: "text-top"}}>Notes From Last Visit:</td>
                    <td width="60%" className="detailsNotes">
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a rhoncus leo. Ut pharetra tellus at lacus interdum sagittis. Curabitur lacinia pretium erat. Vestibulum vel lacus diam. Cras mi purus, tincidunt quis nunc ac, consequat elementum purus. Duis mattis, velit eget aliquam venenatis, neque sem aliquam ante, non tincidunt nulla massa a neque. Fusce elementum libero id massa mollis aliquam.
                        </p>
                        <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc a rhoncus leo. Ut pharetra tellus at lacus interdum sagittis. Curabitur lacinia pretium erat. Vestibulum vel lacus diam. Cras mi purus, tincidunt quis nunc ac, consequat elementum purus. Duis mattis, velit eget aliquam venenatis, neque sem aliquam ante, non tincidunt nulla massa a neque. Fusce elementum libero id massa mollis aliquam.
                        </p>

                    </td>
                </tr>
            </table>
        </Card>

    </Article>
  )
}
