/***
*
*   FEATURES
*   Feature list for use on home/landing pages
*
**********/

import React from 'react';
import { Grid, Icon } from 'components/lib';
import Style from './features.module.scss';

export function Features(props){

  return(
    <div className={ Style.features }>
      <Grid cols={ 3 }>

        <div>
          <Icon image='eye-off' size={ 16 } />
          <h3>Dynamic Redacting</h3>
          <p>Automatically redact confidential data.</p>
        </div>

        <div>
          <Icon image='user-check' size={ 16 } />
          <h3>User Audits</h3>
          <p>Log requesting, viewing and copying of sensitive data per user.</p>
        </div>

        <div>
          <Icon image='activity' size={ 16 } />
          <h3>Monitor</h3>
          <p>Keep an eye on your data and identify bad actors before they act.</p>
        </div>

        <div>
          <Icon image='bar-chart' size={ 16 } />
          <h3>Custom Reports</h3>
          <p>Bespoke reporting puts you in control.</p>
        </div>

        <div>
          <Icon image='code' size={ 16 } />
          <h3>Custom Tagging</h3>
          <p>Fully customizable tagging of valuable data.</p>
        </div>

        <div>
          <Icon image='edit' size={ 16 } />
          <h3>Educate</h3>
          <p>Train employees as they work.</p>
        </div>

        <div>
          <Icon image='check-circle' size={ 16 } />
          <h3>Validate Compliance</h3>
          <p>Confirm your access controls.</p>
        </div>

        <div>
          <Icon image='box' size={ 16 } />
          <h3>Keep Your Data</h3>
          <p>Your confidential data is never transmitted or shared.</p>
        </div>

        <div>
          <Icon image='settings' size={ 16 } />
          <h3>Internal Insights</h3>
          <p>Gain knowledge of risks and threats within your company.</p>
        </div>

        <div>
          <Icon image='lock' size={ 16 } />
          <h3>Security & Permissions</h3>
          <p>Prevent and log copying of sensitive information.</p>
        </div>

        <div>
          <Icon image='bell' size={ 16 } />
          <h3>Immediate Notifications</h3>
          <p>Alert on-access of high risk data.</p>
        </div>

        <div>
          <Icon image='flag' size={ 16 } />
          <h3>Manage Risk</h3>
          <p>Control of your confidential data risks.</p>
        </div>

      </Grid>
    </div>
  );
}
