/***
*
*   HOMEPAGE
*   Homepage template with features, testimonials and CTA
*
**********/

import React, { Fragment } from 'react';
import {Row, Link, Hero, Features, Testimonial} from 'components/lib';
import ImgDashboard from './images/homepage_dashboard.jpg';
import imgBusinessInsider from './images/business_insider.png';
import imgVerizon from './images/verizon_bi.png';

export function Home(props) {

  const availableContent = [
    {
      text: 'Google fired 80 employees for wrongfully accessing data',
      author: "Business Insider",
      image: imgBusinessInsider,
      link: 'https://www.businessinsider.com/google-fired-employees-abusing-user-data-sharing-personal-info-vice-2021-8',
      linkText: 'Reference'
    }, {
      text: '12+ Months Average Breach Time to Discovery within Insider and Privilege Misuse Breaches',
      author: "Verizon Insider Threat Report 2020",
      image: imgVerizon,
      link: 'https://enterprise.verizon.com/resources/reports/insider-threat-report/',
      linkText: 'Reference'
    }
  ];
  const random = Math.floor(Math.random() * availableContent.length);
  const content = availableContent[random];
  return(
    <Fragment>
        <Hero
            title="Secure your PHI, PII and Protected Data"
            tagline="Training, tracking and redacting for your confidential information"
            image={ ImgDashboard }
            />

        <Row title='Features' color='white'>
            <Features />
        </Row>

        <Row color='brand'>
        <Testimonial
          text={content.text}
          author={content.author}
          image={ content.image }
          link={content.link}
          linkText={content.linkText}
        />
      </Row>

      <Row title='Validate your HIPAA / protected / confidential information security.' align='center'>
        <Link btn url='/signup' text='Sign Up Now'/>
      </Row>
    </Fragment>
  );
}
