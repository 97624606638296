/***
*
*   HERO
*   Hero section on landing page
*
*   PROPS
*   title: h1 title
*   tagline: h2 tagline
*   image: source object
*   alt: img alt tag
*
**********/

import React from 'react';
import {Animate, Content, Link, Image, RedactedHolder} from 'components/lib';
import Style from './hero.module.scss';

export function Hero(props){

  return (
    <section className={ Style.hero }>
      <Content>

        <Animate type='pop'>
          <section className={ Style.blurb }>
            <h1>{ props.title }</h1>

            <RedactedHolder />

            <h2>{ props.tagline }</h2>

            <Link btn big url='/signup' text='Sign Up Now' />
          </section>
        </Animate>

        <div style={{ minHeight: "300px", }}>
        <Animate>
          <Image className={ Style.visual } source={ props.image } alt={ props.alt } style={{
            imageRendering: "-webkit-optimize-contrast",
            willChange: "transform",
            transform: "translateZ(0)",
          }} />
        </Animate>
        </div>

      </Content>
    </section>
  )
}
