import {RedactedItem} from "../../lib";
import React from "react";

export function RedactedHolder(props){

    return (
        <div style={{
            border: "1px solid #000",
            borderRadius: "8px",
            backgroundColor: "#ffffff",
            color: "#404040",
            padding: "1em",
            boxShadow: "0 1em 1.25em rgb(0 0 0 / 20%)",
            display: "flex",
            flexWrap: "wrap",
            margin: "2em auto 2em auto",
            fontWeight: "500",
            fontSize: "0.9em",
            textAlign: "left",
            width: "67%",
        }}>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Name: Jason&nbsp;<RedactedItem>Smith</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Date of Birth:&nbsp;<RedactedItem text="Protected - DOB">January, 2, 1934</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Phone #: 555-<RedactedItem>123-4567</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Address: 123&nbsp;<RedactedItem>1st Street</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                State: Florida
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Zip Code:&nbsp;<RedactedItem>32800</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Account #:&nbsp;<RedactedItem text="Protected - Account #">ACC-1234567</RedactedItem>
            </div>

            <div style={{ minWidth:"250px", padding:"0.5em", position:"relative", width:"50%", whiteSpace:"no-wrap", }}>
                Social Security #:&nbsp;<RedactedItem text="Protected - SSN">123-45-6789</RedactedItem>
            </div>

        </div>
    );

}
